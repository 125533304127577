import useHttp from "App/hooks/use-http";

const useCommunityHelper = () => {
  let { triggerAPI } = useHttp();
  const getUserCollectionDataAPI = (data, callback) => {
    triggerAPI(
      {
        url: `community/creators/collections`,
        data,
        method: "post",
      },
      (res) => {
        const { list, pageMeta } = res?.data;
        callback({ list, pageMeta });
      }
    );
  };
  const getCreatorCategories = (data,callback) => {
    triggerAPI({
        url: `creatorCategory/get`,data, method: 'post',
      }, (res => {
        callback(res?.data?.list)
      }));
  }
  return {
    getUserCollectionDataAPI,getCreatorCategories
  };
};
export default useCommunityHelper;
