import cardanoWalletIcon from "Assets/Icons/Blockchains/cardano.png";
import ethWalletIcon from "Assets/Icons/Blockchains/ethereum.png";
import baseWalletIcon from "Assets/Icons/Blockchains/base.png";
import polygonWalletIcon from "Assets/Icons/Blockchains/polygon.png";
import optimismWalletIcon from "Assets/Icons/Blockchains/optimism.png";
import arbitrumWalletIcon from "Assets/Icons/Blockchains/arbitrum.png";
import avalancheWalletIcon from "Assets/Icons/Blockchains/avalanche.png";
import binancesmartchainWalletIcon from "Assets/Icons/Blockchains/binancesmartchain.png";
import bitcoinWalletIcon from "Assets/Icons/Blockchains/bitcoin.png";
import tezosWalletIcon from "Assets/Icons/Blockchains/tezos.png";
import solanaWalletIcon from "Assets/Icons/Blockchains/solana.png";
import linearWalletIcon from 'Assets/Icons/Blockchains/linear.png';
import zoraWalletIcon from 'Assets/Icons/Blockchains/zora.png';
import seiWalletIcon from 'Assets/Icons/Blockchains/sei.png';
import shapeWalletIcon from 'Assets/Icons/Blockchains/shape.png';
import immutableZkEVMWalletIcon from 'Assets/Icons/Blockchains/immutablezkevm.png';
import apecoinWalletIcon from 'Assets/Icons/Blockchains/apecoin.png';
import abstractIcon from 'Assets/Icons/Blockchains/abstract.png';
export const getBlockchainIcon = (code) => {
  switch (code) {
    case "cardano":
      return cardanoWalletIcon;
    case "ethereum":
      return ethWalletIcon;
    case "base":
      return baseWalletIcon;
    case "polygon":
      return polygonWalletIcon;
    case "optimism":
      return optimismWalletIcon;
    case "arbitrum":
      return arbitrumWalletIcon;
    case "avalanche":
      return avalancheWalletIcon;
    case "bsc":
      return binancesmartchainWalletIcon;
    case "bitcoin":
      return bitcoinWalletIcon;
    case "tezos":
      return tezosWalletIcon;
    case "linea":            
        return linearWalletIcon;
    case "zora":
        return zoraWalletIcon;
    case "sei":
        return seiWalletIcon;
    case "solana":
      return solanaWalletIcon;
    case "shape":
      return shapeWalletIcon;
    case "immutable-zkevm":
      return immutableZkEVMWalletIcon;
    case "apechain":
      return apecoinWalletIcon;
    case "abstract":
      return abstractIcon;
    default:
      break;
  }
};

export const marketplaceSupportedChains = () => {
  return [
      "abstract",
      "arbitrum",
      "avalanche",
      "base",
      "blast",
      "ethereum",
      "klaytn",
      "optimism",
      "polygon",
      "sei",
      "solana",
      "zora",
      "bitcoin",
  ]
}